.titleColumn {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 80%;
    align-self: center;
}

.divCreateNewCard {
    justify-content: end;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    background-color: #eeeeee;
    display: flex;
    cursor: pointer;
    max-height: 35px;
}

.divCreateNewCard:hover {
    font-size: 14px;
    cursor: pointer;
}

.subjectCard {
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: #1b2330;
    color: white;
}

.descriptionCard {
    padding: 10px;
    height: 100px;
    overflow-y: auto;
}

.cardKanban {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}

.buttonCreateNewColumn {
    background-color: #1d1f20;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 10px;
    margin-top: 15px;
    color: white;
    font-weight: 600;
    min-width: 10px;
    width: 30px;
    border-radius: 20px;
    align-self: center;
    justify-content: center;
    max-height: 30px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.cardContent {
    padding: 5px;
    width: 100%;
}

.cardTotalWinValueAndBusiness,
.cardTotalProgressValueAndBusiness,
.cardTotalProposalsLeads,
.cardTotalValueAndBusiness {
    margin-top: 2px;
    text-align: center;
    color: green;
    display: flex;
    justify-content: end;
    margin: 0px 10px;
}

.cardTotalWinValueAndBusiness>span {
    color: green;
}

.cardTotalWinValueAndBusiness>span,
.cardTotalProgressValueAndBusiness>span,
.cardTotalProposalsLeads>span,
.cardTotalValueAndBusiness>span {
    font-size: 15px;
    margin: 0px 5px;
    align-self: center;
}


.cardTotalProgressValueAndBusiness,
.cardTotalProgressValueAndBusiness>span {
    color: #0049bf;
}

.cardTotalValueAndBusiness,
.cardTotalValueAndBusiness>span {
    color: #1d1f20
}

.cardTotalProposalsLeads,
.cardTotalProposalsLeads>span {
    color: #d60606
}

.divViewCardCContent {
    display: flex;
    min-width: 800px;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
    height: 40px;
}

.divViewCardCContentObservation {
    min-width: 800px;
    min-height: 60px;
    align-items: center;
    padding: 10px;
    border-radius: 10px;

}


.labelViewCardCContentObservation {
    font-weight: bold;
    padding: 5px;
    font-size: 18px;
}


.divViewCardCContent :first-child {
    font-weight: bold;
    padding: 5px;
    font-size: 18px;
    margin-left: 5px;
}


.divViewCardCContent :nth-child(2) {
    padding: 5px;
    font-size: 18px;
}

.buttonChangeViewMode {
    background-color: #1d1f20;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: -5px;
    margin-left: 25px;
    color: white;
    font-weight: 600;
    min-width: 200px;
}

.exportButton {
    height: 20px;
    width: 20px;
    zoom: 1.5;
    padding: 10px;
}

.listCards>div {
    max-height: 79vh;
    border-radius: 10px;
    max-width: 15vw;
}


.funnelDiv {
    width: 100%;
    background-color: white;
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    max-height: 50px;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
}

.funnelDiv>p {
    margin: 10px;
    font-size: 14px;
    font-weight: 500;
}

.winCard {
    background-color: rgba(74 231 137 / 25%);
    border: 2px solid #068332;
}

.awaitingCard {
    background-color: rgba(38, 88, 225, 0.25);
    border: 2px solid #1908d0;
}

.notrealizedCard {
    background-color: rgba(231, 117, 3, 0.25);
    border: 2px solid #933607;
}

.lossCard {
    border: 1px solid #e21616;
    background-color: rgba(244, 94, 68, 0.25);

}

.buttonGroup {
    background-color: white;
    height: 50px;
    align-items: center;
    align-self: center;
    margin-top: 15px;
    margin-left: 10px;
    display: flex;
}

.buttonGroup>button {
    border-radius: 0px;
}

.buttonSelected {
    background-color: #1b2330;
    color: white;
}

.buttonSelected:hover {
    background-color: #1b2330;
    color: white;
}

.infoDiv {
    display: flex;
    margin-top: 10px;
}

.infoDiv>p {
    font-size: 16px;
    margin: 0px 10px
}

.infoValue {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(27, 35, 48, 20%);
}

.inputSearch {
    margin: 3px;
    border: 1px solid #e9dede;
    border-radius: 5px;
    padding: 0px;
}

.clientItemList {
    padding: 10px;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.searchClientsInput {
    border: 1px solid #c7c2c2;
    border-radius: 10px;
    padding: 5px;
    position: sticky !important;
    top: 70px;
    z-index: 100;
    background-color: white;
}

.searchClientsInput input {
    font-size: 14px;
}