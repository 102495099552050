.imageLogoHome {
    width: 100%;
    height: 300px;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    color: white;
    justify-content: flex-end;
    align-items: end;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.nameHomeWelcome {
    height: 60px;
    width: 100%;
    background-color: white;
}

.nameHomeWelcome>p {
    font-size: 22px;
    padding: 10px;
    font-weight: 500;
    text-align: center;
}

.gridHome {
    display: inline-grid;
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    margin: 10px 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.eventActivity {
    max-height: 75;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 5px;
    border-radius: 5px;
    margin-top: 7px;
}