.answer {
    background-color: white;
    height: 35vw;
    width: 97%;
    padding: 10px;
    margin: 10px 35px;
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.answerChat {
    background: #e1dede;
    border-radius: 15px;
    font-size: 15px;
    width: 90%;
    height: auto;
    position: relative;
    padding: 10px;
    align-self: flex-start;
    width: auto;

}



.answerChat:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #e1dede;
    transform: rotate(20deg);
    bottom: -10px;
    left: 0;
    /* Move a div para a direita */

}


.answerUser {
    position: relative;
    text-align: end;
    right: 0;
    background: #5b5b5b;
    border-radius: 15px;
    font-size: 15px;
    width: auto;
    height: auto;
    padding: 10px;
    color: #f3efef;
    margin-top: 15px;
    margin-left: 8vw;
    align-self: flex-end;

}

.answerUser:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #5b5b5b;
    transform: rotate(-20deg);
    bottom: -10px;
    right: 0;
}

.loading-dots {
    display: inline-block;
}

.dot {
    font-size: 30px;
    opacity: 0;
    animation: blink 1s infinite;
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}