.table_input input {
    margin-left: auto;
    margin-right: auto;
    height: 35px;
    border-radius: 5px;
    width: 90%;
    margin-left: 5%;
    text-align: center;
}

.table_td {

    margin-left: auto;
    margin-right: auto;
    height: 35px;
    border-radius: 5px;
    width: 8%;
    margin-left: 2%;
    text-align: center;
}


.label_values {
    margin: auto;
    height: 35px;
    border-radius: 5px;
    width: 22%;
    margin-left: 5%;
    font-size: 16px;
}

.title {
    font-weight: bold;
}

.buttonRemoveProduct {
    color: white;
    background-color: #252f3e;
    width: 40px;
    height: 40px;
    border-radius: 55px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 20%;
}

.buttonRemoveProduct button {
    font-weight: bolder;
}

.input {
    margin-left: auto;
    margin-right: auto;
    height: 35px;
    border-radius: 5px;
    width: 90%;
    text-align: center;

}

.changeStatusButton {
    display: flex;
    justify-content: center;
    color: black;
    border-radius: 20px;
    width: 160px;
    text-align: center;
    height: 30px;
    background-color: white;
    align-items: center;
    font-weight: 500;
    margin-left: 30px;
    cursor: pointer;
}