.text_label {
    font-size: 20px;
    font-weight: 600;
    width: '100%';

}

.text_value {
    font-size: 14px;
    width: '100%';
    text-align: left;

}


.text_value_files {
    font-size: 14px;
    width: '100%';
    text-align: left;
    padding: 5px;
    margin-top: 10px;
}

.text_value_files:hover {
    font-size: 14px;
    width: '100%';
    text-align: left;
    color: lightblue;
}

.card {
    display: inline-grid;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin: 5px 10px;
    min-height: 110px;
}

.card_multiple {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin: 5px 20px;
    min-height: 150px;

}