.titleActivity {
    font-size: 17px !important;
    font-weight: 600 !important;
}

.labelActivity {
    font-size: 15px !important;
}

.contentActivity {
    margin-bottom: 10px !important;
    margin-left: 10px !important;
    padding: 10px !important;
    border-radius: 10px;
}

.linkActivity {
    color: blue;
    cursor: pointer;
    margin: 2px;
}