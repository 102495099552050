.contactItem {
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    color: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding: 8px 16px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(255, 255, 255);
    font-size: 14px !important;
    margin-top: -2px;
}



.listItem:hover,
.listItem:hover .contactItem {
    background-color: rgb(245, 237, 237);
    transform: scale(1.01);
    transition: 0.5s;
}