.table_input input{
    margin-left: auto;
    margin-right: auto;
    height: 35px;
    border-radius: 5px;
    width: 90%;
    margin-left: 5%;
    text-align: center;
}

.table_td{
    
    margin-left: auto;
    margin-right: auto;
    height: 35px;
    border-radius: 5px;
    width: 8%;
    margin-left: 2%;
    text-align: center;
}


.title {
    font-weight: bold;
}

.button {
    color: white;
    background-color: #252f3e ;
    width: 10px;
    height: 10px;
    max-width: 20px;
    border-radius: 55px;
    margin-left: 20%;
    text-align: center;
}
.button button {
    font-weight: bolder;
}
.input{
    margin-left: auto;
    margin-right: auto;
    height: 35px;
    border-radius: 5px;
    width: 90%;
    text-align: center;
   
}