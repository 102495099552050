.title {
    font-size: 20px;
    font-weight: 600;
    width: '100%';
    text-align: left;
    height: 50px;
    border-bottom: 1px solid rgb(194, 190, 190);
}

.title_mailings {
    font-size: 14px;
    font-weight: 600;
    width: '100%';
    text-align: center;
    height: 60px;
    border-bottom: 1px solid rgb(194, 190, 190);
}

.value {
    font-size: 18px;
    margin-top: 5px;
    font-weight: 400;
    width: '100%';
    text-align: left;

}

.value_mailings {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 400;
    width: '100%';
    text-align: center;

}


.cardDash {
    display: inline-grid;
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    margin: 10px 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.paramsGrid {
    display: inline-grid;
    width: 90%;
    padding: 20px;
    border-radius: 10px;
    margin: 10px !important;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.chart {
    display: inline-grid;
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    margin: 10px 20px;
    background-color: white;
    height: 650px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#last10 .title {
    font-size: 20px;
}

#last10 .render10 {
    max-height: 300px;
    overflow-x: auto;
}

#last10 .renderSingle {
    height: 70px;
    border-bottom: 1px solid #b6b4b4;
    padding: 10px;
    display: grid;
    cursor: pointer;
}

#last10 .renderSingle span {
    font-size: 16px;
}

#last10 .renderSingle span:first-of-type {
    font-weight: 500;
}

.btnHeaders {
    display: flex;
    margin-right: 20px;
}

.btnHeaders div {
    width: 200px;
    height: 60px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block {
    background: skyblue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.static {
    background: blue;
}

.droppable-element {
    height: 50px;
    width: 250px;
    background: skyblue;
    margin: 10px;
    border: 2px dashed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Header {
    min-height: 60px;
    background-color: #8d8d8dc9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Header span {
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;
}

.Header button {
    background-color: white;
    display: flex;
    min-width: 200px;
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
    justify-content: space-around;
    font-weight: bold;
    align-items: center;
}

.graph {
    background-color: white;
    border-radius: 5px;
}

.trashzone {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: red;
    opacity: 0.6;
    border-radius: 10px;
    color: white;
}

.buttonsGrid {
    position: absolute;
    right: 35px;
    top: 0;
    z-index: 1000;
    background-color: #e7e7e7;
    height: 35px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.buttonsGraph {
    z-index: 1000;
    background-color: #ededed;
    width: 100px;
    height: 45px;
}


.fade-in {
    opacity: 1;
    /* Torna os botões visíveis com a transição */
}

.tableDash {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    border-radius: 10px;
    height: inherit;
}

.floating-button-container {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 999;
    /* Adicione um z-index mais alto */
}

.layout{
    background-color: rgb(248 248 248 / 55%);
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: opacity 0.3s ease, max-height 0.3s ease;
    margin-bottom: 10px;
}

.button-group.visible {
    opacity: 1;
    max-height: 200px;
    /* Ajuste a altura para caber todos os botões */
}

.toggle-button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    transition: background-color 0.3s ease;
}

.action-button {
    background-color: #28a745;
    border: none;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    z-index: 10000;
    margin-top: 10px;
    transition: transform 0.3s ease;
    /* Animação quando o botão é expandido */
}

.action-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
    /* Efeito hover */
}

.expanded .toggle-button {
    background-color: #dc3545;
}

.expanded .button-group {
    display: flex;
}

.react-grid-item {
    text-align: center;
    align-content: center;
}