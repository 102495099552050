.fieldFormula_,
.operatorFormula_ {
    background-color: #474f5b;
    height: 25px;
    color: white;
    text-align: center;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
}

.operatorFormula_ {
    background-color: #848991;
}

.fieldFormula_dragging,
.operatorFormula_dragging {
    background-color: white;
    height: 25px;
    color: #474f5b;
    text-align: center;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
}

.gridFormula {
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
}

.numberFormula_ {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    height: 25px;
    text-align: center;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
}