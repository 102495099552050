.input {
    padding: 10px;
    height: 35px;
    border-radius: 5px;
    width: 98% !important;
    text-align: start !important;
    border: 1px solid rgba(149, 157, 165, 0.2);
}

.label {
    height: 35px;
    width: 25%;
    text-align: center;
    font-weight: 500;
    margin: 10px !important;
}