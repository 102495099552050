

.form input {
    border: 1px solid hsla(0, 0%, 65%, 0.575);
    padding: 9px;
    margin: 5px;
    border-radius: 5px;

}

.form label {
    margin: 5px;
    font-weight: 500;
}

.fileList {
    color: 'white'
}

.select {
    margin: 6px;
    border-radius: 10px;
    width: 100%;
}

.select>:first-child {
    border-radius: 10px;
    height: 40px;
}

.divFile {
    padding: 5px;
    background-color: #ECEBEA;
    margin: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.buttonFile {
    border-radius: 20px !important;
    width: 40px;
    font-weight: bolder;
    background-color: #1b2330;
    color: white;
    cursor: pointer;
}

.labelFile {
    cursor: pointer;
}

.buttonSubmit {
    border-radius: 20px !important;
    width: 120px;
    height: 30px;
    font-weight: bolder;
    background-color: #1b2330;
    color: white;
    cursor: pointer;
    margin-top: 10px;
}

.imageDiv {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    color: white;
}

.imageLabel {
    cursor: pointer;
    font-weight: bold;
}

.buttonOpacity {
    opacity: 0.5;
}

.css-1nmdiq5-menu {
    z-index: 10 !important;
}