.cardProposal {
    display: inline-grid;
    width: 100%;
    padding: 10px;
    margin: 0px 5px;
    min-height: 75px;
    background-color: white;
}

.cardProposal label {
    font-size: 18px;
    font-weight: 600;
}

.cardProposal p {
    font-size: 15px;
}

.gridContainer {
    background-color: rgb(207, 207, 207);
}
.gridContainer h1{
    color: white;
    padding: 10px;
    font-weight: 600;
}


.gridItem{
    padding: 1px !important;
    
}